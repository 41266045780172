<template>
    <audio v-if="block.sound" ref="sound" :src="block.sound.url"></audio>
</template>

<script>
export default {
  components: {},
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {},
  sockets: {
    controllerData(data) {
      if(data.name == this.block.blockName){

        switch(data.output) {
            case 'play':
                this.play();
            break;
            case 'stop':
                this.stop();
            break;
        }
      }
      console.log(data);
    },
  },
  methods: {
    play(){
        this.$refs.sound.currentTime = 0;
        this.$refs.sound.play();
    },
    stop(){
        this.$refs.sound.pause();
    },
  },
  beforeDestroy() {
    this.$refs.sound.pause();
  },
 
};
</script>
